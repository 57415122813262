import React from 'react';

const AmbassadorPage = () => {
  return (
    <div className="bg-black text-white min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-16">
        {/* Hero Section */}
        <section className="mb-16">
          <h1 className="text-5xl font-bold mb-4"><span className="text-green-600">CoinGate</span>Pad</h1>
          <h2 className="text-3xl font-semibold mb-4">Ambassador Program</h2>
          <p className="mb-6">
            Eligible participants: KOLs with more than 10,000 followers.
            We highly value innovative ideas with positive potential and
            encourage contributions that go beyond the outlined scope.
            Each contribution will be thoughtfully reviewed and
            individually evaluated. For a complete understanding of how
            to contribute effectively, please refer to our detailed
            guidelines, which outline the process and criteria.
          </p>
          <a
            href="https://forms.gle/7xbeedVWSjSUoKcx8"
            className="bg-green-400 text-black px-6 py-3 rounded-full text-lg font-semibold hover:bg-green-500 transition duration-300 inline-block"
          >
            Submit your application
          </a>
        </section>

        {/* Earn Points Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6">Earn points with the following activities:</h2>
          <ol className="list-decimal pl-5 space-y-4">
            <li>Share promotional posts about CoinGatePad on social media platforms, including project information, bot usage guides, campaign events, collaboration news, or important feature updates. Introduce new users or Partners to join the community. Please provide content links when submitting for the monthly review.</li>
            <li>Create art such as stickers, memes, etc., about CoinGatePad.</li>
            <li>Support and engage with the CoinGatePad community on Twitter and Telegram by answering questions and educating users.</li>
            <li>Actively engage with social media posts on official channels (e.g., retweet, comment, like). If the CoinGatePad Twitter account retweets your tweet, you can earn extra points per tweet.</li>
          </ol>
        </section>

        {/* Monthly Report Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6">Monthly Report Submission</h2>
          <p className="mb-6">
            Ambassadors must submit a detailed Monthly Report using
            the provided template. This report is crucial for tracking
            your contributions and ensuring you receive appropriate
            recognition. Key details to include are the Date of each
            contribution, the specific Contribution type, a direct Link to
            where the contribution can be verified, and the Points
            awarded based on our established framework.
          </p>
          <a
            href="https://forms.gle/aTFufysnGQCfanYz5"
            className="bg-green-400 text-black px-6 py-3 rounded-full text-lg font-semibold hover:bg-green-500 transition duration-300 inline-block"
          >
            Submit Monthly Report
          </a>
        </section>

        {/* Submission Guidelines Section */}
        <section>
          <h2 className="text-3xl font-semibold mb-6">Submission Guidelines</h2>
          <ol className="list-decimal pl-5 space-y-4 mb-6">
            <li>The deadline for submitting the form is between the 30th and the 5th of each month. This window allows you ample time to compile and reflect on your monthly activities.</li>
            <li>Points Tally and Rewards: The admin team will meticulously tally points and rewards by the 10th of the following month. Timely submissions ensure that your hard work is acknowledged and rewarded promptly.</li>
            <li>By adhering to this process, you play an integral role in maintaining the program's integrity and efficiency.</li>
          </ol>
          <p>
            Increase your contributions to earn more points. Top
            ambassadors will receive additional $CGP bonuses for
            great referrals and engaging posts. We honor and reward
            the dedication of our passionate ambassadors.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AmbassadorPage;