import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ExternalLinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    <polyline points="15 3 21 3 21 9"></polyline>
    <line x1="10" y1="14" x2="21" y2="3"></line>
  </svg>
);

const Home = () => {
  const [activePhase, setActivePhase] = useState('Q3 2024');

  const roadmap = [
    {
      phase: 'Q3 2024',
      items: [
        'Market research and competitive analysis on chains',
        'Team works on a Marketing strategy',
        'Build a user-friendly Telegram Mini App Play-To-Earn',
        'Adding functionality and features to Mini App',
        'Run tests on the platform ensuring it is secure and functional',
        'Expansion of CoinGatePad Team',
        'Establish partnerships with reputable projects and influencers',
        'Reward program for community contributors'
      ]
    },
    {
      phase: 'Q4 2024',
      items: [
        'Global expansion to bring in a wider range of communities',
        'Major partnerships & collaboration to bring more exposure',
        'Beta Launch with test features',
        'Bonus structures and incentives to reward participants and encourage wider participation'
      ]
    },
    {
      phase: 'Q1 - Q2 2025',
      items: [
        'Full Platform Launch',
        'Token Generation Event and Listing',
        'Multi-Chain Integration and Expansion'
      ]
    }
  ];

  const socialLinks = [
    { name: 'TELEGRAM CHANNEL', url: 'https://t.me/CoinGatePad' },
    { name: 'TELEGRAM GROUP', url: 'https://t.me/CoinGatePadChat' },
    { name: 'TWITTER', url: 'https://twitter.com/CoinGatePad' },
    { name: 'DISCORD', url: 'https://discord.gg/pdzrW9sPr2' },
    { name: 'FACEBOOK', url: 'https://facebook.com/coingatepad' },
  ];

  return (
    <div className="bg-black text-white min-h-screen">
      <main className="max-w-4xl mx-auto px-4">
        {/* Hero Section */}
        <section className="py-16">
          <h1 className="text-5xl font-bold mb-4"><span className="text-green-600">CoinGate</span>Pad</h1>
          <p className="text-xl font-bold mb-8">One Platform, Infinite Chains, Boundless Potential</p>
          <p className="mb-8">
            CoinGatePad is more than just a launchpad. It's a vibrant ecosystem where innovation thrives, and
            new possibilities emerge. CoinGatePad welcomes you to join us on this exciting journey into the
            future of decentralized finance.
          </p>
          <a href="https://t.me/CoinGatePadBot" className="bg-green-400 text-black px-6 py-3 rounded-full text-lg font-semibold hover:bg-green-500 transition duration-300 inline-block">
            PLAY NOW
          </a>
        </section>

        {/* About Section */}
        <section id="about" className="py-16">
          <h2 className="text-4xl font-bold mb-4">About <span className="text-green-600">CoinGate</span>Pad</h2>
          <p className="text-lg mb-4">
            CoinGatePad is a decentralized exchange (DEX) launchpad for the world of cryptocurrencies
            across multiple chains. We unlock limitless opportunities, offering seamless fundraising
            and broad exposure across the crypto ecosystem.
          </p>
          <p className="text-lg mb-4">
            At CoinGatePad, we believe in empowering the next generation of crypto projects and
            facilitating their seamless entry into the market.
          </p>
          <a href="/CoinGatePad-Whitepaper.pdf" className="bg-green-400 text-black px-6 py-3 rounded-full text-lg font-semibold hover:bg-green-500 transition duration-300 inline-block">
            Read Whitepaper
          </a>
        </section>

        {/* Roadmap Section */}
        <section id="roadmap" className="py-16">
          <h2 className="text-4xl font-bold mb-6">Roadmap</h2>
          <div className="space-y-8">
            <div className="mb-6">
              {roadmap.map((phase) => (
                <button
                  key={phase.phase}
                  className={`px-4 py-2 rounded-full mr-2 mb-2 ${
                    activePhase === phase.phase ? 'bg-green-400 text-black' : 'bg-gray-700 text-white'
                  }`}
                  onClick={() => setActivePhase(phase.phase)}
                >
                  {phase.phase}
                </button>
              ))}
            </div>
            <ul className="list-none pl-0">
              {roadmap.find(phase => phase.phase === activePhase)?.items.map((item, index) => (
                <li key={index} className="flex items-start mb-2">
                  <span className="text-green-400 mr-2">•</span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </section>

        {/* Contact Us Section */}
        <section id="socials" className="py-16">
          <h2 className="text-4xl font-bold mb-8">Connect With Us</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-green-400 text-black px-4 py-3 rounded-full text-center text-sm font-semibold hover:bg-green-500 transition duration-300 flex items-center justify-center"
              >
                <span className="mr-2">{link.name}</span>
                <ExternalLinkIcon className="w-4 h-4" />
              </a>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;