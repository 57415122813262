import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Ambassador from './components/AmbassadorPage';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div className="min-h-screen bg-black text-white font-sans flex flex-col">
        <header className="bg-green-900 p-4">
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            <Link to="/">
              <img src="/logo.png" alt="CoinGatePad Logo" className="h-10" />
            </Link>
            <nav className="hidden md:flex gap-6">
              <Link to="/" className="text-white hover:text-green-400">Home</Link>
              <Link to="https://www.coingatepad.com/#about" className="text-white hover:text-green-400">About</Link>
              <Link to="/ambassador" className="text-white hover:text-green-400">Ambassador</Link>
              <Link to="https://www.coingatepad.com/#roadmap" className="text-white hover:text-green-400">Roadmap</Link>
              <Link to="https://www.coingatepad.com/#socials" className="text-white hover:text-green-400">Contact Us</Link>
            </nav>
            <button onClick={toggleMenu} className="md:hidden text-white">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </header>

        {isMenuOpen && (
          <div className="md:hidden bg-green-800">
            <Link to="/" className="block py-2 px-4 text-white hover:bg-green-700" onClick={toggleMenu}>Home</Link>
            <Link to="https://www.coingatepad.com/#about" className="block py-2 px-4 text-white hover:bg-green-700" onClick={toggleMenu}>About</Link>
            <Link to="/ambassador" className="block py-2 px-4 text-white hover:bg-green-700" onClick={toggleMenu}>Ambassador</Link>
            <Link to="https://www.coingatepad.com/#roadmap" className="block py-2 px-4 text-white hover:bg-green-700" onClick={toggleMenu}>Roadmap</Link>
            <Link to="https://www.coingatepad.com/#socials" className="block py-2 px-4 text-white hover:bg-green-700" onClick={toggleMenu}>Contact Us</Link>
          </div>
        )}

        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ambassador" element={<Ambassador />} />
           </Routes>
        </main>

        <footer className="bg-green-900 p-4 text-center">
          <p>&copy; 2024 CoinGatePad. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;